import React from "react";

import Layout from "components/Layout";
import Container from "components/Container";
import Intro from "components/Intro";
import { SEO } from "components/SEO";

const PrivacyPolicyPage = () => {
	return (
		<Layout pageName="privacy-policy">
			<SEO />
			<Intro className="privacy-wrap legal-notice__intro temp-color">
				<h1 className="mono">
					Privacy Policy headline einleitend zum thema
				</h1>
				<h2>Privacy Policy</h2>
			</Intro>
			{/* 
			<Container sectionName="privacy-wrap legal-notice-block legal-notice__policy">
				<h2 className="copy-large--lh">Policy</h2>
			</Container>
			<Container sectionName="privacy-wrap legal-notice-block legal-notice__waiver">
				<h2 className="copy-large--lh">Waiver to this policy</h2>
			</Container>
			<Container sectionName="privacy-wrap legal-notice-block legal-notice__waiver">
				<h2 className="copy-large--lh">Reporting and Enforcement</h2>
			</Container> */}
			<Container sectionName="privacy-wrap legal-notice-block legal-notice__policy temp-color">
				<h2 className="copy-large--lh">Policy</h2>
				<p className="temp-color">
					At Herman Miller, we believe our conduct matters. It is
					important to do the right thing. Living with integrity and
					following a clear ethical code has created a good business,
					a great place to work, and a trustworthy investment. That
					conduct transcends all areas of our business, including how
					we deal with each other internally and how we deal with
					customers, suppliers, alliance partners, shareholders and
					governments. Remember: We respect the whole person, and we
					celebrate exploration, transparency, design, performance,
					engagement, relationships, and inclusiveness because these
					are „Things That Matter“ to us.
				</p>
				<p className="temp-color">
					Our Corporate Code of Conduct reflects these important core
					values. Our Code of Conduct sets forth our basic ethical
					standards and core values that are applicable to all of us
					all of the time. Our Code is designed to guide us in our
					work activities wherever we are located. It helps guide us
					on how to conduct our business and directs us where to go
					when we have questions or concerns. As a guide, our Code of
					Conduct also allows us to value the diversity of cultures,
					people and their experiences.
				</p>
				<p className="temp-color">
					All employees of Herman Miller and its subsidiaries are
					responsible for understanding and complying with the Code of
					Conduct. By living our values and upholding our Code of
					Conduct, you will help ensure that we continue to strive to
					achieve the right results, the right way.
				</p>
			</Container>
			<Container sectionName="privacy-wrap legal-notice-block legal-notice__waiver temp-color">
				<h2 className="copy-large--lh">Waiver to this policy</h2>
				<p className="temp-color">
					Any waiver of this policy must be given in writing. Waivers
					may be requested through the Legal Services Department.
					Waivers respecting financial officers, executive officers,
					and directors may require approval by the Board of Directors
					or the Nominating and Governance Committee of the Board of
					Directors and will be promptly disclosed to the Company’s
					General Counsel and the Director of Business Risk.
				</p>
			</Container>
			<Container sectionName="privacy-wrap legal-notice-block legal-notice__waiver temp-color">
				<h2 className="copy-large--lh">Reporting and Enforcement</h2>
				<p className="temp-color">
					Any breach of the above policy may result in disciplinary
					action in accordance with the company’s policies as outlined
					in the Working Together Guide or policy manual that is in
					effect at the particular location.
				</p>
				<p className="temp-color">
					If an employee suspects possible violations of the Code of
					Conduct, Herman Miller policies or laws, such employee has
					the responsibility to contact People Services or Herman
					Miller’s Legal Services Department at 616 654 8035
					immediately. If the employee wishes to remain anonymous
					he/she may contact the Theft and Fraud Hotline at 888 840
					2387 or 770 582 5209 (from locations outside the USA and
					Canada). An employee may also choose to file a report using
					our confidential web-based Internet reporting tool. This
					reporting tool is staffed by our vendor partner “The
					Network,” and anonymity is guaranteed.
				</p>
				<p className="temp-color">
					Regardless of how any suspected violation is reported, there
					will be no form of reprisal for doing so. Herman Miller will
					investigate fully all matters related to alleged violations
					and take appropriate action in accordance with the policies
					outlined in the Working Together Guide or policy manual that
					is in effect at the particular location. It is everyone’s
					responsibility to help create and ensure an effective
					workplace. Employees are expected to participate fully in
					any investigation of alleged misconduct.
				</p>
			</Container>
		</Layout>
	);
};

export default PrivacyPolicyPage;
